// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import "~swiper/scss";

//**************  Pics  **************//
@import './theme/images.scss';

/**************   CSS CABRI  **************/
// optional?
@import 'assets/cabri2/styles/cabri.css';
@import 'assets/cabri2/lib/jquery-ui-1.12.0.custom/jquery-ui.min.css';
@import 'assets/cabri2/lib/jquery-ui-1.12.0.custom/jquery-ui.structure.min.css';
@import 'assets/cabri2/lib/jquery-ui-1.12.0.custom/jquery-ui.theme.min.css';
@import 'assets/cabri2/css/chunk-vendors.css';
@import 'assets/cabri2/css/app.css';


.preloadImage {
  display: block;
  top:-100vh;
  height: var(--documentHeight);
  width: 100%;
  position: fixed;
  left: 0;
}

.babylonUnmuteIcon{
  top:var(--babylonUnMountTop)!important;
}

.startButtonCabri {
    text-transform: none!important;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: var(--appFontFamily);
    font-size: 20px!important;
    // font-size: calc(0.5vh + 0.5vw)!important;
    color: white!important;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    // height: 100%;
    // width: 50%;
    background: linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
}

// alerts clmc
.waitUserActionAlertTransparent {
  background: transparent;
}

.backgroundSettings {
  background: url(/assets/toolbar/settings-light.jpg) no-repeat center center / cover !important;
}
.low-perf-mode .backgroundSettings,
.low-perf-mode :host .backgroundSettings,
.low-perf-mode.backgroundSettings {
  background: url(/assets/toolbar/settings-light_ios.jpg) no-repeat center center / cover !important;
}

.wUABackground {
  background: url(/assets/gabarits/fond-etoile.jpg) no-repeat center center / cover;
  ion-backdrop {
    background: transparent;
  }
}
.low-perf-mode .wUABackground{
  background: url(/assets/gabarits/fond-etoile_ios.jpg) no-repeat center center / cover !important;
  ion-backdrop {
    background: transparent;
  }
}

.waitUserActionAlert .alert-wrapper { 
    .alert-button-group {
      justify-content: space-evenly;
      flex-wrap:nowrap!important;
    }
  }

  .waitUserActionAlert .alert-message {
    font-size: 19px;
    color: #00AFEC;
    font-family: var(--appFontFamily);
  }

  .waitLandscapeModeAlert {
    background: url(/assets/gabarits/fond-etoile.jpg) no-repeat center center / cover;
    // --alertContentColor: #00AFEC;
    --alertContentColor: #FFF;
    .alert-wrapper {
      background: transparent;
      .alert-head {
          height: 25vh;
          margin: 5vh 0 2vh 0;
          background-color: var(--alertContentColor);
          -webkit-mask-image: url(./assets/icon/rotate.svg);
          mask-image: url(./assets/icon/rotate.svg);
          mask-position: center;
          mask-repeat: no-repeat;
          mask-size: contain;
          // transform: rotateY(180deg);
      }
      .alert-message {
        font-size: 20px;
        color: var(--alertContentColor);
        font-family: var(--appFontFamily);
      } 
      .alert-button-group {
        justify-content: space-evenly;
      }
    }
  }
  .low-perf-mode .waitLandscapeModeAlert {
    background: url(/assets/gabarits/fond-etoile_ios.jpg) no-repeat center center / cover !important;
  }

.participantNativeDismissed {
  background:linear-gradient(180deg, #FF7ABA 0%, #EE0072 100%);
  position: relative;
  width: 41%;
  height: 100%;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// players slots holo:

.participantHolo {
  position: absolute;
  margin-left: 13%;
  bottom: 0;
  min-width: 32%;
  max-width: 65%;
  padding: 1.9% 2.3%;
  max-height: 81%;
  background: linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.participantCurrentHolo {
  position: absolute;
  margin-left: 13%;
  bottom: 0;
  min-width: 32%;
  max-width: 65%;
  padding: 1.9% 2.3%;
  max-height: 81%;
  background: #00EDC8;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.participantDismissedHolo {
  position: absolute;
  margin-left: 13%;
  bottom: 0;
  min-width: 32%;
  max-width: 65%;
  padding: 1.9% 2.3%;
  max-height: 81%;
  background:linear-gradient(180deg, #FF7ABA 0%, #EE0072 100%);
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.participantCurrentNameHolo {
  font-family: var(--appFontFamily);
  color: #FFFFFF;
  font-size: 2.5vh;
  transform: rotateY(180deg);
}

// awards holo:

.holoAward {
  width: 100%;
  height: 65%;
  left: unset;
  position: absolute;
  transition-property: width, height, left; 
  transition-duration: 1.5s; 
  transition-timing-function: ease; 
}

.shootingToStarboard {
  width: 7%;
  height: 11.5%;
  left: 5.7%;
  position: absolute;
  transition-property: width, height, left; 
  transition-duration: 1.5s; 
  transition-timing-function: ease; 
}

.normalToStarboard {
  width: 8%;
  height: 12%;
  left: 14.8%;
  position: absolute;
  transition-property: width, height, left; 
  transition-duration: 1.5s; 
  transition-timing-function: ease; 
}

.moonToStarboard {
  width: 8.2%;
  height: 12.1%;
  left: 24.2%;
  position: absolute;
  transition-property: width, height, left; 
  transition-duration: 1.5s; 
  transition-timing-function: ease; 
}

.shootingAwardHolo {
  width: 100%;
  height: 100%;
  background-image: url(/assets/starboard/awardshighdef/shooting.svg);
  background-size: 40% auto;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: rotateX(
    180deg
    );
}

.normalAwardHolo {
  width: 100%;
  height: 100%;
  background-image: url(/assets/starboard/awardshighdef/normal.svg);
  background-size: 27% auto;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: rotateX(
    180deg
    );
}

.moonAwardHolo {
  width: 100%;
  height: 100%;
  background-image: url(/assets/starboard/awardshighdef/moon.svg);
  background-size: 25% auto;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: rotateX(
    180deg
    );
}

.awardFadeOut {
  width: 100%;
  height: 65%;
  opacity: 0;
  position: absolute;
  transition-property: width, height, left, opacity; 
  transition-duration: 1.5s; 
  transition-timing-function: ease; 
}

// start joureny HUD alert 
.assignationInfo,.logbookInfo{
  // top: calc(var(--toolbarHeight) + var(--babylonUnMountTop) + 5px);
  top: 50%;
  transform: translate(0%, -50%);
  bottom: unset;
  position: fixed;
  width: 17%;
  height: 18%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  box-shadow: -1px 5px 15px 4px rgba(0, 0, 0, 0.55);
}

.assignationHeader, .logbookHeader{
  display: flex;
  justify-content: center;
  background-color: rgba(65, 105, 225, 0.445);
  text-shadow: 1px 1px 25px #83f7c7, 1px 1px 10px #83f7c7;
  height: 20%;
  width: 100%;
  font-family: var(--appFontFamily);
  align-items: center;
  color: white;
  font-size: calc(1vw + 1vh);
}

.assignationBody,.logbookBody{
  display: flex;
  width: 100%;
  height: 80%;
  cursor:pointer;
}

.externalLinkIcon{
  margin: 0;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.assignationBody, .logbookBody{
  background-color:rgba(255,255,255,0.4);
}

.externalAccessBorder{
  border-bottom-left-radius: 25px;     
  border-bottom-right-radius: 25px
}

.assignationWrapper{
  background-image: url(/assets/img/hud/light.png);
}

.assignationWrapperAnim {
  animation:shake .3s ease-in-out .3s infinite alternate;
}

.logbookWrapper{
  background-image: url(/assets/img/hud/sen.png);
}

@keyframes shake{
  from{
      transform: rotate(10deg);
  }
  to{
      transform:rotate(-10deg);
      transform-origin:center center;
  }
}

//  end joureny HUD alert with cellphone





//end HUD

// Start Stars level
.emptyStar{
  background: url(/assets/img/etoile_grise_niveau.png);
  background-size: cover;
}

.filledStar{
  background: url(/assets/img/etoile_niveau.png);
  background-size: cover;
}
  // close button
.modalHeadercloseButton {
  font-size: 2em;
  color: white;
  cursor:pointer;
}

ion-icon.modalHeadercloseButton {
  --ionicon-stroke-width: 60px;
}

.modalHeadercloseButtonContainer{
  position: absolute;
  background-color: #e4931e;
  width: 30px;
  height: 30px;
  z-index: 10;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
}

.modalHeadercloseButtonMobileContainer {
  width: 25px;
  height:25px;
}

 .validateChoice{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-family: var(--appFontFamily);
      font-size: 24px;
      color: white;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      height: 70%;
      padding:1rem;
      min-width:15%;
      background-color: #e4931e;
  }

.elementList{
    color: #fff;
    display:flex;
    justify-content: space-between;
    background: #014e6c;
    align-items: center;
    border-radius: 20px;
    min-width: 150px;
    height:100%;
    text-align: center;
    cursor: pointer;
}

  // Scrollbar
::-webkit-scrollbar {
  width: 15px;
  border-radius: 10px;
  background-color: #F5F5F5; 
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%)!important;
  background: -moz-linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%)!important;
  background: -webkit-linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%)!important;
  background: -webkit-linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%)!important;
  background: -o-linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%)!important;
  background: -ms-linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%)!important;
}

// selected item
.selectedItem{
  box-shadow: 0 0 0 0.1rem #fff, 0 0 0.4rem #00afec, 0 0px 0.6rem #00afec, 0 0 0rem #00afec, 0 0 0 0.15rem #00afec, inset 0 0 0rem #fff
}

// modal common modal journey and gabarit
.emptyStar{
  width:1.5em;
  height:1.5em;
  background: url(/assets/img/etoile_grise_niveau.png);
  background-size: cover;
}

.filledStar{
  width:1.5em;
  height:1.5em;
  background: url(/assets/img/etoile_niveau.png);
  background-size: cover;
}


.modalContentWrapper{
  width:100%;
  display:flex;
  align-items: center;
  font-family: 'Quicksand-Bold';
}

:root {
  --settingsMenuColor: white;
  --hudContentHeight: 70%;
  --assignationModalHeaderHeight:80px;
  --hudAnimationDuration: 0.5s;
  --babylonUnMountTop: 60px;
  --logbookMenuWidth: 135px;
}

// dev range for fxaa on babylon pages
.rangeFxaa {
  --bar-height: 30%!important;
  --bar-border-radius: 50px!important;
  --bar-background-active: #1FCDB9!important;
  --bar-background: rgb(31, 205, 185) !important;
  --knob-background: #FFE600 !important;
  --knob-size: min(calc(2.3vh + 2.3vw), 34px);
  --knob-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.labelFxaa {
  position: fixed;
  top: 35vh;
  left: 5vw;
  width: auto;
  height: auto;
  font-size: 1.5em;
  color: black;
}

// simple ion-alert from global service (demo mode):
.simpleAlert {
  ion-modal::part(backdrop) {
    --backdrop-opacity: 1;
    // background: #cbebf7;
    background: rgba(0, 177, 231, 0.26);
  //   background: url(/assets/gabarits/fond-etoile.jpg) no-repeat center center / cover;
  }
  
  .alert-wrapper {      
    .alert-button-group {
      justify-content: space-evenly;
      flex-wrap:nowrap!important;
    }
  }

  .alert-message {
    font-size: 1.2em;
    color: #00AFEC;
    font-family: "Quicksand-Bold";
  }
}

.alertConfirm .alert-message{
  font-size: 15px;
  font-family: var(--appFontFamily);
}


.pointer {
  cursor: pointer;
}

@media screen and (max-width: 900px)
{
  :root {
    --babylonUnMountTop: 45px;
  }
    .babylonUnmuteIcon
    {
      width:35px!important;
      height:25px!important
    }
}

// fix black border on canvas babylon
// https://forum.babylonjs.com/t/remove-border-of-rendering-canvas/16074
canvas {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}

// dev menu in toolbar
.devMenuContainerToolbar {
  position: fixed;
  height: calc(var(--toolbarHeight) - 2px);
  top: 1px;
  left: 25%;
  width: 55%;
  margin-left: 2%;
  z-index: 99999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.tempMenuToolbar {
  font-size: 0.8em;
  background-color: rgba(0, 140, 255, 0.2);
  width: 20%;
  height: 100%;
  margin-right: 2px;
  color: #00B3E7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonContainerToolbar {
  flex-direction: row!important;
}

// Big mic
.bigMicIconWrapper {
  width: 20vw;
  height: 20vw;
  position: absolute;
  bottom: 0%;
  left: 0%;
  transform: translateX(-30%) translateY(40%);
  z-index: 1999;
}

.bigMicIconWrapperMirror {
  width: 20vw;
  height: 20vw;
  position: absolute;
  bottom: 0;
  left: 0px;
  transform: translateX(-35%) translateY(46%) rotateZ(360deg);
  z-index: 9999;
  pointer-events: none;
}

.bigMicSvg {
  height: 100%;
  width: 100%;
}

.bigMicHidden {
  opacity: 0;
  z-index: -1;
}

.bigMicStatic {
    background: url(/assets/kidaia/icones/bigmic0.svg) no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 100%;
}

.bigMicAnim { 
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  animation: changeBg 2s infinite; 
}
@keyframes changeBg{
   0%,100%  {background-image: url(/assets/kidaia/icones/bigmic0.svg);}
   25% {background-image: url(/assets/kidaia/icones/bigmic1.svg);}
   50% {background-image: url(/assets/kidaia/icones/bigmic2.svg);}
   75% {background-image: url(/assets/kidaia/icones/bigmic3.svg);}
}

// Simple pulse animation
.bigMicCircle {
  fill: #f73f97; 
  // animation: circlePulse 2.5s cubic-bezier(0.015, 0.7, 0.855, 1) infinite;
}
// @keyframes circlePulse {
//   0%  {fill-opacity: 0;}
//   // 25% {fill-opacity: 0.25;}
//   50% {fill-opacity: 1;}
//   100% {fill-opacity: 0;}
// }

// Pulse animation - waiting for center adjust:
// .pulsating-circle-bigMic {
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   &:before {
//     content: '';
//     position: relative;
//     display: flex;
//     width: 100%;
//     height: 100%;
//     // left: 4%;
//     // top: -15%;
//     box-sizing: border-box;
//     border-radius: 500px;
//     background-color: #f73f97;
//     animation: pulse-ring-big 2.5s cubic-bezier(0.015, 0.7, 0.855, 1) infinite;
//   }
// }
// @keyframes pulse-ring-big {
//   0% {
//   transform: scale(.33);
//   }
//   80%, 100% {
//   opacity: 0;
//   }
// }

// mic waves blinking anim:
.fillBigMicWave {
  fill: white;
}

.bigMicWaveAnimation {
  animation: mic-wave 1.5s steps(1, end) infinite;
}

@keyframes mic-wave {
  // 0%  {fill-opacity: 1;}
  // 33% {fill-opacity: 0;}
  // 66% {fill-opacity: 0;}
  // 100% {fill-opacity: 1;}
  0%  {fill-opacity: 1;}
  33% {fill-opacity: 1;}
  49% {fill-opacity: 1;}
  66% {fill-opacity: 0;}
  100% {fill-opacity: 0;}
}
.fillBigMicWave1 { 
  animation-delay: 0s;
}
.fillBigMicWave2 { 
  animation-delay: -1s;
}
.fillBigMicWave3 { 
  animation-delay: -2s;
}

// modal babylon for when audio engine is locked:

ion-modal.audioEngineLockedModal::part(content) {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  width: 100% !important;
  height: 100% !important;
}

#renderCanvas1:not(.renderCanvas1HoloMode){
  // background: url(assets/gabarits/fondsKidaia/cabri_default/stars_default.jpg) no-repeat center center / cover;
  background-color: #1f1c55!important;
}

.renderCanvas1HoloMode {
  background-color: #000000!important;
}

// settings & activitysettings menus

.kidaiaBigIconSlotMenu {
  background-size: 90% auto;
  width: 80%;
  height: 80%;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  position: fixed;
}

.kidaiaBigIconSlotProgressBarMenu {
  background-size: 85% auto;
  width: 100%;
  height: 100%;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  position: fixed;
}

.closeIconMathia {
  background-image: url(/assets/toolbar/close2.png);
}

.closeIconKidaia {
  background-image: url(/assets/kidaia/toolbar/close_fullscreen.svg);
}

.iconDefaultMenuOpened {
  background-size: 90% auto;
  height: 36px;
  width: 34px;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  position: fixed;
}

// dev menu activity V2
.devMenuContainerV2 {
  position: fixed;
  height: var(--toolbarHeight);
  top: 6px;
  left: 28vw;
  width: 10%;
  z-index: 99999 !important;
}
.devMenuContainerV2Opened {
  width: 50%;
}

.devButtonContainerV2 {
  display: flex;
  position: relative;
  left: 0px;
  z-index: 9900;
  background: rgba(12, 35, 79, 0.8);
  width: 100%;
  height: 85%;
  justify-content: flex-start;
  align-items: center;
  opacity: 90%;
  padding: 0 0.5vh;
}

.devButtonV2 {
  font-size: 1.8vh;
  background-color: rgba(0, 140, 255, 0.20);
  width: 20%;
  height: 6vh;
  color: #00B3E7;
  margin: 0 0.5vh;
}

.devOpenButtonV2 {
  width: 95%;
}

.devBtnV2ON {
  background-color: green;
}

.devBtnV2OFF {
  background-color: red;
}

.close-icon-global {
  position: absolute;
  right: 2vh;
  top: 2vh;
  z-index: 10;
  height: calc(3vw + 3vh);
  width: calc(3vw + 3vh);
}
.close-icon-global__img {
  background-image: url(/assets/icon/close.svg);
  background-size: 100% auto;
  width: 100%;
  height: 100%;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  cursor: pointer;
}


// custom alert ionic
.customAlert .alert-wrapper{
  min-height: 30%;
    min-width: 60%;
    max-height: 30%;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: "Quicksand-Bold";

}

.customAlert .alert-wrapper .alert-message {
  font-size: min(2vh + 1.5vw,1.8em);
  color: #2111d9;
  text-align: center;
}

.customAlert .alert-wrapper .alert-head{
  display: none;
}

.customAlert .alert-wrapper .alert-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
  align-content: space-around;
}

.customAlert .alert-wrapper .alert-button-group .alert-button{
  font-size: 4.5vh;
  color: #fff;
  max-width: 40%;
  width: 40%;
  min-width: 40%;
  border-radius: 45px;
  padding-top: 4%;
  padding-bottom: 4%;
}

.customAlert .alert-wrapper .alert-button-group .greenButtonCustomAlert{
  background-color: #54efca;
}

.customAlert .alert-wrapper .alert-button-group .redButtonCustomAlert{
  background-color: #f4539e;
}

#inspector-host {
  width: auto !important;
  position: absolute !important;
  top: var(--toolbarHeight)!important;
  z-index: 1;
}

#scene-explorer-host {
  width: auto!important;
  position: absolute !important;
  height: calc(100% - var(--toolbarHeight))!important;
  top: var(--toolbarHeight)!important;
  z-index: 2;
}

.xr-button-overlay {
  top: 90px !important;
  bottom: unset !important;
}

.vrdisplaypresenting::after {
    content: "SORTIR" !important;
}


.lockPremium.relative {
  position: relative;
}

.lockPremium:after {
  content: "";
  width: inherit;
  height: inherit;
  background: url(/assets/img/hud/tableau-recompenses/cadenas.png) no-repeat;
  background-size: contain  ;
  background-position: center;
  position: absolute;
  cursor: pointer;
  backdrop-filter: blur(4px);
  border-radius: inherit;
  border-width:inherit ;
}

// ion skip button next title 
.title-wrapper{
  position: relative;
  .skip-button{
    --ion-color-primary: var(--oseBlue);
    position: absolute;
    left: 102%;
    top: 0%;
    font-size: calc(1.1vh + 1.1vw);
    height: 30px;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.alertFeedback .alert-wrapper {
  background: #FFBC38;
  text-align: center;
  color : #081e40ed;
}
