// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

.logoMathiaWhiteText {
  // background: url(/assets/mathia/logo_fond_transparent_solo_white.png) no-repeat center center / cover;
  background: url(/assets/mathia/logo_fond_transparent_solo_white.png) no-repeat bottom center/contain;
}

/** Ionic CSS Variables **/
*{
  font-weight: 600 ;
  font-family: var(--appFontFamily) ;
}
:root {
  /** app font **/
  --appFontFamily: "Quicksand-Bold";
  font-family: var(--appFontFamily) ;
  font-weight: 600 ;
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #56f1d0;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;




  	//  App Custom Colors:

	// mathiaFontColor
  
	--ion-color-mathiaFontColor: #00AFEC;

    // Custom Color Classes Generator for ionic:
    $customColors: ('mathiaFontColor', 'mishaPurple');
    @each $color in $customColors {
        .ion-color-#{$color} {
        --ion-color-base: var(--ion-color-#{$color}) !important;
        --ion-color-base-rgb: var(--ion-color-#{$color}-rgb) !important;
        --ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
        --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
        --ion-color-shade: var(--ion-color-#{$color}-shade) !important;
        --ion-color-tint: var(--ion-color-#{$color}-tint) !important;
        }
    }

.gradientFrame {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  // align-self: flex-start;
  // width: 100vw;
  // max-width: 100vw;
  // height: 100vh;
  // border: double 15px transparent;
  // border-radius: 0px;
  // background-image: linear-gradient(white, white), linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
  // background-origin: border-box;
  // background-clip: content-box, border-box;
}

.inputMathia {
  width: 75vw;
  border: double 4px transparent;
  border-radius: 45px;
  background-image: linear-gradient(white, white), linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  margin: 7vh;
  text-align: center;
  font-size: 3vh;
  font-family: var(--appFontFamily);
  color: #C4C4C4;
}

.gradientBubble {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: flex-start;
  width: auto;
  max-width: 60vw;
  height: auto;
  border: double 4px transparent;
  border-radius: 30px 30px 30px 0px;
  background-image: linear-gradient(white, white), linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.gradientBorderGlobal {
    border: double 4px transparent;
    border-radius: 30px 30px 30px 0px;
    background-image: linear-gradient(white, white), linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }

.btnNoDecoration {
  border: none;
  outline:none;
}

// Toolbar / ProgressBar height global variable:
// --toolbarHeight: --> init in toolbar.component.ts / checkToolbarStatus()
// --progressBarHeight --> init in toolbar.component.ts / checkToolbarStatus()

--burgerMarginLeft: 6px;

.dyslexic-mode .dyslexicFontHeight {
  line-height: 1.25 !important;
}

// Variables Ose²:

// colors:
--oseGreen: #88b627;
--oseGreenText: #82b000;
--oseDarkGreen: #364911;
--oseRed: #da001a;
--osePink: #FF005C;
--oseOrange: #fa9a00;
--oseWhite: #f3ffd9;
// --oseWhiteTransparent: #f3ffd9e8;
--oseWhiteTransparent: white;
--oseBlue: #3777ff;

// borders:
--oseBorderGreen: #82B000 calc(var(--documentHeight) * 0.01) solid;
--oseBorderGreenSmall: #82B000 calc(var(--documentHeight) * 0.007) solid;
--oseBorderMobileGreen: #82B000 calc(var(--documentHeight) * 0.015) solid;
--oseBorderWhite: var(--oseWhite) calc(var(--documentHeight) * 0.01) solid;
--oseBorderMobileWhite: var(--oseWhite) calc(var(--documentHeight) * 0.015) solid;
--oseBorderRed: var(--oseRed) calc(var(--documentHeight) * 0.01) solid;
--oseBorderMobileRed: var(--oseRed) calc(var(--documentHeight) * 0.015) solid;
--oseBorderOrange: var(--oseOrange) calc(var(--documentHeight) * 0.01) solid;
--oseBorderMobileOrange: var(--oseOrange) calc(var(--documentHeight) * 0.015) solid;
--oseBorderWhite: white calc(var(--documentHeight) * 0.01) solid;
--oseBorderMobileWhite: white calc(var(--documentHeight) * 0.015) solid;

--oseBorderRadiusRound: calc(var(--documentHeight) * 0.5);
--oseBorderRadiusBubble: calc(calc(var(--documentHeight) * 0.02) + 1.5vw);

// gutemberg style 
--wp--style--gallery-gap-default: 20px;

// colors from wordpress:
// $newColorPalette = [
//     [
//         'name' => 'Vert Kidaia',
//         'slug' => 'vert-kidaia',
//         'color' => '#88b627',
//     ],
//     [
//         'name' => 'Vert moyen ',
//         'slug' => 'vert-moyen-kidaia',
//         'color' => '#9ab854',
//     ],
//     [
//         'name' => 'Vert clair',
//         'slug' => 'vert-clair-kidaia',
//         'color' => '#f3ffd9',
//     ],
//     [
//         'name' => 'Vert très foncé',
//         'slug' => 'vert-fonce-kidaia',
//         'color' => '#364911',
//     ],
//     [
//         'name' => 'Jaune',
//         'slug' => 'jaune-kidaia',
//         'color' => '#F3EB02',
//     ],
//     [
//         'name' => 'Vert noir ',
//         'slug' => 'vert-noir-kidaia',
//         'color' => '#222f04',
//     ],
//     [
//         'name' => 'Vert blanc',
//         'slug' => 'vert-blanc-kidaia',
//         'color' => '#f3ffd9',
//     ],
//     [
//         'name' => 'Bleu kidaia',
//         'slug' => 'bleu-kidaia',
//         'color' => '#6E00F2',
//     ],
// ];
}
$font-path: "../assets/fonts/MaterialIcons";
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url($font-path + '/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url($font-path + '/MaterialIcons-Regular.woff2') format('woff2'),
    url($font-path + '/MaterialIcons-Regular.woff') format('woff'),
    url($font-path + '/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}
