// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These 
// styles are for the entire app and not just one component. Additionally, this 
// file can hold Sass mixins, functions, and placeholder classes to be imported 
// and used throughout the application.

// not necessary?:
// @import '@angular/material/prebuilt-themes/purple-green.css';
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// @import '~animate.css/animate.min';

/** fonts **/
@font-face {
  font-family: 'Quicksand-Bold';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/quicksand/Quicksand-Light.ttf');
}

@font-face {
  font-family: 'Quicksand-Bold';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/quicksand/Quicksand-Regular.ttf');
}

@font-face {
  font-family: 'Quicksand-Bold';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/quicksand/Quicksand-Medium.ttf');
}

@font-face {
  font-family: 'Quicksand-Bold';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/quicksand/Quicksand-SemiBold.ttf');
}

@font-face {
  font-family: 'Quicksand-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/quicksand/Quicksand-Bold.ttf');
}

.appFontFamPreload {
  font-family: var(--appFontFamily);
}

@font-face {
  font-family: 'round-italic';
  src: url('/assets/fonts/round/Typo_Round_Light_Italic_Demo.otf') format('truetype');
} 

@font-face {
  font-family: 'graphecritjunior';
  src: url('/assets/fonts/graphecritjunior.otf') format('truetype');
}

@supports (-webkit-touch-callout: none){
  @font-face {
    font-family: 'graphecritjunior';
    src: url('/assets/fonts/graphecritjunior.otf') format('truetype');
    font-weight: bold;
  }
}

@font-face {
  font-family: 'dyslexic-regular';
  src: url('/assets/fonts/dyslexic/OpenDyslexic-Regular.woff') format('woff');
}

@font-face {
  font-family: 'dyslexic-bold';
  src: url('/assets/fonts/dyslexic/OpenDyslexic-Bold.woff') format('woff');
}

@font-face {
  font-family: 'BradleyHandITCTT-Bold';
  font-style: bold;
  font-weight: 700;
  src: url('/assets/fonts/Bradley-Hand-ITC-TT-Bold.ttf');
}

  .preload {
    opacity: 0;
    position: absolute;
    left: 0;
    pointer-events: none;
  }

  .swal2-container{
    background:darkgray!important;
  }

  .my-swal {
    background: rgba(255,255,255,.8);
    backdrop-filter: blur(2px);
  }

  .ecriture-cursive, 
  .ecriture-cursive p{
    font-family: 'graphecritjunior';
    font-weight: bold;
  }
  //Rating µ
  ngb-rating .sr-only {
    display:none!important;
  }

  // Button clicked

  .buttonClicked {
    background:  rgb(253, 243, 94)!important;
  }

  //Progress bar
  .progress-bar .progress-outer{
    margin:0px!important;
    min-width:100%!important;
    padding:0px!important;
  }
  
  .progress-bar .progress-inner{
    padding:2px!important;
  }

  ion-toast.custom-toast-internet, ion-toast.toastModeAdventureDisabled{
    font-size:1.35em;
    text-align: center;
    font-family: var(--appFontFamily);
  }

  
  // ion-menu
  
    .customItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 16px
    }

    .toggleBlue {
        margin-left: 16px;
    }

    .toggleCustom {
        padding-inline-start: 0 !important;
    }

    .fontFam {
        font-family: var(--appFontFamily)!important;
        color: #009ad0 !important;
    }

    .selectedVoice {
        width: 100%;
    }

  // .voiceOptions {
  //   height: 70vh;
  // }

  .rgpdLink{
    text-decoration: none;
    color:inherit;
  }

  .itemBtns {
    color: black;
    font-size: 100%;
    padding-right: 100%;  
  }

  .muteButton {
    --margin-inline-end: 0px !important;
    margin-right: 0 !important;
  }

  .imgSplashScreenTuto {
    height: 90%;
    width: auto;
    margin: auto;
  }

  .messageTextCalcul{
    font-family: var(--appFontFamily);
    font-size: calc(2vh + 1vw);
    display: flex;
    align-items: center;
    text-align: center;
    color: #00AFEC;
    margin: auto;
  }

  /*********** MODAL PARTICIPANT ******************/
  .student-list-modal{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    --background: transparent;
    --backdrop-opacity: 0.5;
  }

   ion-modal.student-list-modal::part(content){
    width: 90%;
    height: 90%;
    border: red 1px solid;
    border-radius: 20px;
    border: double 4px transparent;
    border-radius: 30px 30px 30px 30px;
    background-image: linear-gradient(white, white), linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: flex-start;
  }

  ion-modal.student-list-modal::part(content) ion-grid{
    display: flex;
    flex-basis: content;
    flex-wrap: wrap;
    justify-content: center;
    height:inherit;
  }

  .hidden{
    display: none !important;
  }

  ion-app.fadeOut{
    animation: fadeOut 1s ease-out forwards;
  }
  
  @keyframes fadeOut {
      from {
      opacity: 1;
      }
      to {
        opacity: 0;
      }
  }

  .blueLightFilter {
    position: absolute;
    z-index: 9999999!important;
    pointer-events: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgb(255, 255, 191);
    mix-blend-mode: multiply;
  }

  .noBlueLightFilter {
    opacity: 0;
  }


  /***** Directive Tootip *****/

  .ng-tooltip {
    position: absolute;
    max-width: 150px;
    // font-size: calc(1.5vh + 1.5vw);
    font-size: 20.5px;
    text-align: center;
    font-family: var(--appFontFamily);
    color: #00AFEC;
    padding: 3px 8px;
    z-index: 1000;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    align-self: flex-start;
    width: auto;
    max-width: 60vw;
    height: auto;
    border-radius: 30px 30px 30px 0px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    background: white;
    border-image-source: linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
    border: solid 3px;
  }
  .ng-tooltip:after {
    content: "";
    position: absolute;
    border-style: solid;
  }
  .ng-tooltip-top:after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: black transparent transparent transparent;
  }
  .ng-tooltip-bottom:after {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: transparent transparent black transparent;
  }
  .ng-tooltip-left:after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent transparent transparent black;
  }
  .ng-tooltip-right:after {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent black transparent transparent;
  }
  .ng-tooltip-show {
    opacity: 1;
  }


  .way_icon h3{
    font-size: 40px;      
    background:-moz-linear-gradient(top, #e72c83 0%, #a742c6 100%); 
      background: -webkit-linear-gradient(top, #e72c83 0%,#a742c6 100%); 
      background: linear-gradient(to bottom, #e72c83 0%,#a742c6 100%);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
     -webkit-text-fill-color:transparent;
     }

.way_icon i:before {
display: inline;
}

#vKeyboard {
  display: none;
  color: red;
}

// Utils cursor
.cursorPointer{
  cursor:pointer;
}

// Groups participants card


.gr_bubbles, .gr_Container{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border: double 4px transparent;
  border-radius: 30px 30px 30px 30px;
  // padding: 0% 0.5%;
  padding: 1%;
}

.gr_StudentNames{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 7rem);
  align-items: center;
  text-align:center;
}

.gr_Container{
  padding: 1% 0.5%;
  align-items: center;
}

.gr_StudentNames p{
  font-size: 1.1em;
  font-family: var(--appFontFamily);
  padding: 1px 0 0 0;
  color: #fff;
  background: #00AFEC;
  border-radius: 4px;
  line-height: 24px;
}

.gr_studentNamesContainer{
  width: 90%;
}


.gr_StudentNamesTitleContainer{
  width: 90%;
  display: flex;
}


.gr_StudentNamesTitleBlock{
  border-top:    2px solid  white ;
  border-right:  2px solid white ;
  border-left:   2px solid  white ;
  padding:2px 8px;
}

.gr_StudentNamesTitleBlock h3{
  font-size: 1.2em;
  color:white;
  font-family: 'Quicksand-Bold';
  margin:2px;
}

.gr_Divider{
  width:80%;
  background-color: white;
  height:2px;
  margin:5px auto;
}


.gr_bubbles ion-col{
  padding: 0.5% 0.2%;
}

.gr_gradientBubbles{
  display:flex;
  flex-direction: row;
  width:100%;
  height:100%;
  align-items: center;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(white, white), linear-gradient( 
  180deg
, #83dfff 0%, #00afec 100%);
  background-origin: border-box;
  background-clip: content-box, border-box; 
  background: #00AFEC;
}

.gr_IconsContainer{
  display: flex;
  flex-wrap:wrap;
  height:100%;
  justify-content: center;
  align-items: center;
  font-size: 1.9rem;
  background: #00AFEC;
  border: 2px solid #00AFEC;
  color: #FFFFFF;
  border-radius: 50%;
  border:3px solid white;
  width:7rem;
  height:7rem;
  padding:5px;
}

.gr_IconsContainerMT{
  width:6rem;
  height:6rem;
}


.gr_IconsContainer.twoRows ion-icon{
  width:49%;
}

@media screen and (max-width: 1024px) {
  .gr_Divider{
    margin:2px auto;
    width:90%;
  }

  .gr_StudentNames p{
    font-size:1em;
  }

  .gr_StudentNamesTitleBlock h3{
    font-size: 1em;
  }
}
.is-ios #canvasholder1.mirrorMode{
  overflow: auto !important;
}
.mirrorMode {
  transform: rotateX(180deg);
}

.participantsContainerBrowser {
  position: fixed;
  bottom: 0;
  top: unset;
  width: 47%;
  left: 26.5%;
  height: 17%;
  /* height: calc(100% - var(--toolbarHeight)); */
  pointer-events: none;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.participantCurrent {
  min-width: 50%;
  max-width: 79%;
  padding: 5px 5px;
  max-height: 81%;
  background: linear-gradient(
180deg
, #83DFFF 0%, #00AFEC 100%);
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.participantMirrorMode {
  bottom: unset;
  transform: rotateX(180deg);
  top: calc(var(--toolbarHeight) + var(--progressBarHeight));
}

.displayCMHoloRemoteWaitingScreen {
  position: absolute !important;
  top: 0!important;
  width: 100%!important;
  height: 100%!important;
  z-index: 1002!important;
}

.opacityNone {
    opacity: 0;
}

.devBtnOn {
  color: rgb(54, 231, 0)!important;
}

.devBtnOff {
  color: red!important;
}



/************** INIT CABRI *****************/

:host ::ng-deep #progresscanvasCabriApp0{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}


:host ::ng-deep #progresscanvasCabriApp0{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

:host ::ng-deep #cabriWrapper{
  overflow: hidden !important;
}

//   #page-div{
//     // left: 0 !important;
//     // top: var(--toolbarHeight)!important;
//     // width: 100vw !important;
//     // height: calc(100% - var(--toolbarHeight)) !important;
//     // z-index: 60;
//     // //allow click in page-div (cabri container)
//     // pointer-events: auto !important;
//   }

#renderCanvas1{
  left: 0px ;
  // min-width: 100vw !important;
  // //height: auto !important;
  // width: 100% !important;
}
//   #canvasholder1{
//     // min-height: 100vh;
//   }

#maindiv1, #canvasholder1{
  z-index: 40;
  background-color: #202E3F;
}
/************** END INIT CABRI *****************/


ion-modal.galaxyMapClass::part(content), ion-modal.rewardModalClass::part(content), ion-modal.videoModalClass::part(content) {
  background-color: transparent;
    overflow: unset;
}

ion-modal.galaxyMapClass::part(content){
  width: 61% !important;
  height: 74% !important;
}

@media screen and (max-width: 1200px) {
  ion-modal.galaxyMapClass::part(content){
    width: 80% !important;
    height: 70% !important;
  }
}

@media only screen and (max-height: 520px) and (orientation: landscape) {
    ion-modal.galaxyMapClass::part(content){
      width: 50% !important;
      height: 60% !important;
    }
}

ion-modal.rewardModalClass::part(content){
  width: 40% !important;
  height: 62% !important;
}

@media all and (orientation:portrait) {  
  ion-modal.rewardModalClass::part(content){
    height:  40% !important;
    width: 62% !important;
  }
}


 ion-modal.rewardModalClass::part(content)::before {
  content: "";
  position: absolute;
  width: 120%;
  height: 110%;
  left: -10%;
  top: -5%;
  //  background: top/contain no-repeat url(/assets/img/hud/header.png), center/100% 86% no-repeat url(/assets/img/hud/center.png), bottom/contain no-repeat url(/assets/img/hud/footer.png);
  background: url(/assets/img/hud/header.png) top/100% 6% no-repeat, url(/assets/img/hud/center.png) center/100% 91% no-repeat, url(/assets/img/hud/footer.png) bottom/100% 5.5% no-repeat;
  background-clip: border-box;
  overflow: hidden;
}
ion-modal.videoModalClass::part(backdrop) {   
  pointer-events: none; 
  display: none; 
}
ion-modal.videoModalClass ::ng-deep .modal-shadow{
  pointer-events: none; 
  display: none; 
}
ion-modal.videoModalClass::shadow .modal-shadow{
  pointer-events: none; 
  display: none; 
}
ion-modal.videoModalClass::part(content){
  width: 90%;
  height: 92%;
}
ion-modal.videoModalClass::part(content).sc-ion-modal-ios{
  width: 90% ;
  height: 92% ;
}

ion-modal.videoModalClass::part(content).sc-ion-modal-md{
  width: 90% ;
  height: 92% ;
}
ion-modal.videoModalClass:-webkit-full-screen-ancestor::part(content),
ion-modal.videoModalClass.sc-ion-modal-ios:-webkit-full-screen-ancestor::part(content),
ion-modal.videoModalClass:-webkit-full-screen-ancestor {
  --height: 100vh !important;
  --width: 100vw !important;
  height: 100vh !important;
  width: 100vw !important;
}

ion-modal.videoModalClass::part(content)::before {
  content: "";
  position: absolute;
  width: 106%;
  height: 110%;
  left: -3%;
  top: -5%;
  background: url(/assets/img/hud/header.png) top/100% 6% no-repeat, url(/assets/img/hud/center.png) center/100% 91% no-repeat, url(/assets/img/hud/footer.png) bottom/100% 5.5% no-repeat;
  background-clip: border-box;
  overflow: hidden;
}

ion-modal.videoModalClass app-video{
  display : block;
}


ion-modal.galaxyMapClass::part(content)::before{
  content: "";
  position: absolute;
  width: 110%;
  height: 125%;
  left: -5%;
  top: -12.6%;
  background: url(/assets/img/hud/galaxyMap/header.png) top/100% 10.2% no-repeat, url(/assets/img/hud/galaxyMap/body.png) center/100% 80% no-repeat, url(/assets/img/hud/galaxyMap/footer.png) bottom/100% 10.2% no-repeat;
  background-clip: border-box;
  overflow: hidden;
}


.galaxyMapClass{
  --box-shadow: none!important;
}

ion-modal.modal-inscription::part(content) {
  --height: 90vh;
  width: calc(min(100vw, 900px));
}
#close-modal-inscription{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-image: url('/assets/icon/close.svg');
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
}

.ng-tooltip.tooltipContainer{
  background-color: #00000055;
  border: none;
  border-radius: 25px;
  max-width: 90vw;
  padding: 5px 50px;
  left: 50% !important;
  transform: translateX(-50%);
  width: 95% !important;
}


.ng-tooltip.tooltipContainer.mobile{
  background-color: #000000CC;
}

.ios-animation-fixer {
  position: fixed;
  width: 100%;
  height: 10px;
  top: -10px;
  background-color: green;
  z-index: 1;
  pointer-events: none;
  visibility: hidden;
}

mjx-container[jax="SVG"][display="true"] {
  display: inline-block !important;
  margin: -2px 0px !important;
}

#customLoadingScreenDiv{
  display: block;
  background-color: transparent;
  color: white;
  font-size: 50px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
}
#customLoadingScreenDiv.on{
  opacity: 1;
}
#customLoadingScreenDiv.black{
  background-color: black;
}
#loaderGIF{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 20vh;
  height: 20vh;
  background-color: #574c4c44;
  border-radius: 10vh;
  z-index: 10000;
}

#small-loader{
  display: block;
  background-color: transparent;
  color: white;
  font-size: 50px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
}
#small-loader #loaderGIF {
  background-color: transparent;
}
#small-loader.on{
  opacity: 1;
}
app-toolbar-with-buttons{
  height: var(--toolbarHeight);
}

app-speech-bubble {
  z-index: 17;
}

.settingsHidden {
  display: none;
}

ion-button{
  --box-shadow: 2px 2px 4px rgb(0 0 0 / 25%);
  font-weight: 500;
  text-transform: unset;
  --ion-font-family: var(--appFontFamily);  
  letter-spacing : unset;
  
}
:root {
  --ion-color-success: #54efca ;
  --ion-color-success-contrast: black;
  --ion-color-danger: #F4539E;
  --ion-color-danger-contrast: black;
  --ion-color-primary: #00AFEC;
  --ion-color-primary-contrast: white;
  .button-large {
    font-size: 28px;
    height: 5rem;
    min-width: 200px;
  } 
}
