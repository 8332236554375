
// .env-kidaia-ose, 
// .env-kidaia.page-ose{
  
  // variable injection here (only for Ose environnement):
  
html.backgroundStars:not(.page-kidaia):not(.page-ose){
  background: none!important;
} 
.env-kidaia-ose, 
.page-ose{
.backgroundSettings {
  background: url(/assets/backgrounds/main.jpg) no-repeat center bottom/cover !important;
}

&.low-perf-mode .backgroundSettings,
&.low-perf-mode :host .backgroundSettings,
&.low-perf-mode.backgroundSettings {
  background: url(/assets/backgrounds/main.jpg) no-repeat center bottom/cover !important;
}

.waitUserActionAlert .alert-message {
  font-size: 19px;
  color: var(--oseDarkGreen);
  font-family: var(--appFontFamily);
  font-weight: 700;
}

.leftMenuBackgrd {
  background-image: url(/assets/toolbar/left-menu-opak.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.startButtonCabri {
  background: #88b627 !important;
}

.git-data-slot {
  color: #790000 !important;
}

.mascotte__img {
  background: url(/assets/mascotte/bee.png) no-repeat center center / cover;
  height: 100%;
  width: 100%;
  border-radius: var(--oseBorderRadiusRound);
  border: var(--oseBorderGreen);
}

.oseNextButton,
.osePrevButton {
  position: absolute;
  bottom: 0; 
  z-index: 1000;
  background: white;
  width: 8vh;
  height: 8vh;
  border: var(--oseBorderGreenSmall);
  border-radius: var(--oseBorderRadiusRound);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.oseNextButton{
  right: 0;
  transform: translateY(43%) translateX(43%);

}
.osePrevButton{
  left: 0;
  transform: translateY(43%) translateX(-54%);
}

#oseCloseButton{
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translateX(50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index:1;
  width: 8vh;
  height: 8vh;
}

#oseCloseButton-global__img{
  background-image: url(/assets/icon/close_ose.svg);
  background-size: 100% auto;
  width: 100%;
  height: 100%;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.oseNextButton img,
.osePrevButton img {
  width: 50%;
  margin-left: 10%;
}

.osePrevButton img{
  margin-left: -10%;
  transform: scale(-1, 1);
}

&.backgroundStars, .backgroundStars {
  // background: url(/assets/backgrounds/main.jpg) no-repeat center center / cover !important;
  background: url(/assets/backgrounds/main.jpg) no-repeat center bottom / cover!important;
}

&.low-perf-mode .backgroundStars,
&.low-perf-mode :host .backgroundStars,
&.low-perf-mode.backgroundStars,
&.wait-plt-ready.backgroundStars,
&.wait-plt-ready :host .backgroundStars {
  background: url(/assets/backgrounds/main_ios.jpg) no-repeat center bottom / cover!important;
}

.backgroundStarsIon {
  --background: url(/assets/backgrounds/main.jpg) no-repeat center bottom / cover!important;
}

&.low-perf-mode .backgroundStarsIon,
&.low-perf-mode :host .backgroundStarsIon {
  --background: url(/assets/backgrounds/main_ios.jpg) no-repeat center bottom / cover!important;
}

&.waitLandscapeModeAlert {
  background: url(/assets/backgrounds/main.jpg) no-repeat center bottom / cover!important;
}

&.low-perf-mode .waitLandscapeModeAlert,
&.low-perf-mode :host .waitLandscapeModeAlert,
&.low-perf-mode.waitLandscapeModeAlert {
  background: url(/assets/backgrounds/main_ios.jpg) no-repeat center bottom / cover!important;
}

.wUABackground {
  background: url(/assets/backgrounds/main.jpg) no-repeat center bottom / cover;

  ion-backdrop {
    background: transparent;
  }
}

&.low-perf-mode .wUABackground,
&.low-perf-mode :host .wUABackground,
&.low-perf-mode.wUABackground {
  background: url(/assets/backgrounds/main_ios.jpg) no-repeat center center / cover !important;
}

.waitUserActionAlertHolo {
  --backdrop-opacity: 0;
  background: url(/assets/backgrounds/main.jpg) no-repeat center center / cover;
}

&.low-perf-mode .waitUserActionAlertHolo {
  background: url(/assets/backgrounds/main_ios.jpg) no-repeat center center / cover !important;
}

#logo-ose {
  background: url(/assets/logo_ose.png) no-repeat bottom center/contain;
}

&.low-perf-mode :host #logo-ose,
&.wait-plt-ready :host #logo-ose {
  background: url(/assets/logo_ose_ios.png) no-repeat bottom center/contain;
}


// variable injection here (only for Ose environnement):

// FONTS Charte Ose²:
h1 {
  color: var(--oseDarkGreen);
  font-family: var(--appFontFamily), sans-serif;
  font-size: 32px;
  font-weight: 700;

  .is-mobile & {
    font-size: 20px;
    font-weight: 700;
  }
}

h2 {
  color: var(--oseDarkGreen);
  font-family: var(--appFontFamily), sans-serif;
  font-size: 24px;
  font-weight: 600;

  .is-mobile & {
    font-size: 20px;
    font-weight: 700;
  }
}

h3 {
  color: var(--oseDarkGreen);
  font-family: var(--appFontFamily), sans-serif;
  font-size: 20px;
  font-weight: 700;

  .is-mobile & {
    font-size: 20px;
    font-weight: 700;
  }
}

p {
  color: var(--oseGreenText);
  font-family: var(--appFontFamily), sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600 !important;

  .is-mobile & {
    font-size: 15px;
    line-height: 1.4;
    font-weight: 600 !important;
  }
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--osePink);
  border-radius: 50px;
  padding: 0.75% 1.5%;
}

.btn .icon {
  height: 90%;
  align-content: center;
  display: flex;
  padding: 1px;
}

.btn .icon img {
  max-width: 100%;
  max-height: 100%;
}

.btn .text {
  color: white;
  font-weight: 700;
  font-size: calc(1vh + 1vw);
  padding-left: 1vw;
  padding-right: 1vw;
}

.close-icon-global {
  position: absolute;
  right: 2vh;
  top: 2vh;
  z-index: 10;
  height: calc(3vw + 3vh);
  width: calc(3vw + 3vh);
}

.close-icon-global__img {
  background-image: url(/assets/icon/close_ose.svg);
  background-size: 100% auto;
  width: 100%;
  height: 100%;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  cursor: pointer;
}



.cursor-pointer {
  cursor: pointer;
}

#videosPage .filterVideo p {
  color: white;
  font-size: 1.3vmax;
}
#videosPage .filterVideo {
  background-color: #88b627e0 !important;
}

#videosPage .buttonSelect {
  background-color: #83ae28 !important;
}

#videosPage .descriptionFullText {
  background: rgba(0, 54, 18, 0.57) !important;
}


.videoCardImg:hover:after, .videoImgDescriptionFull.play:after {
  background: url(/assets/icon/play-video-ose.svg) no-repeat;
  background-size: contain;
  background-position: center;
}
.descriptionFullRight .videoImgDescriptionFull.play:after {
  background: url(/assets/icon/play-video-ose.svg) no-repeat;
  background-size: contain;
  background-position: center;
}

#videosPage .videoCardImg:hover:after {
  background: url(/assets/icon/play-video-ose.svg) no-repeat;
  background-size: contain;
  background-position: center;
}


h1.sectionName{
  color: var(--oseWhite);
  font-size: calc(1.2vw + 1.2vh);
  margin: 0;
  text-align: center;
}

p.skip{
  color: #fff;
}

h2.title-container{
  color: #f3ffd9;
  text-align: center;
  margin: 0;
}

p.colorizedItems{
  color: #fff;
  margin: 1%;
  font-size: calc(1.2vh + 1.2vw);
}

#ruche{
  display: block;
}





// reference:
// h1 {
// 	font-size: 36px;
// 	font-weight: 700;
// }
// h2 {
// 	font-size: 24px;
// 	font-weight: 600;
// }
// h3 {
// 	font-size: ?;
// 	font-weight: ?;
// }
// p {
// 	font-size: 18px;
// 	font-weight: 500;
// }
// button {
// 	font-size: 24px;
// 	font-weight: 600;
// }

}