* {
	-webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
	-webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */

	/*user-select: none;*/ /* prevent copy paste, to allow, change 'none' to 'text' */
	-webkit-user-select: none; /* to stop appearing magnifying glass in safari (mobile) */
}
html {
	touch-action: none;
	overflow-x: hidden;
	overflow-y: hidden;
}
body {
	touch-action: none;
    background-color: black;
    margin: 0;
    overflow: hidden;
}

:root {
	/*--selectionColor:#74B5EB;			/*  used for both selection and hiliting... */
	/* here --selectionColor can be specified in hexa or in rgba format
	 to be called e.g. as   DomHelper.getCssVariable("--charterMidBlue")
	 BTW I (JML) would recommend the use of rgb notation which is easy to interpret where HEX is isoteric
	    to convert colors color converter at https://www.w3schools.com/colors/colors_converter.asp is quite handy!
    In css file itself use it using e.g. following syntax    var(--hiliteColor) */

	--hiliteColor:rgb(95,190,217);			/* "#5fbed9" kind of blue   */
	--selectionColor: rgb(255, 177, 43);    /* kind of yellow */
	--matchingColor: #5bfe33; /*a3ff0f*/
	--textColor:#000000ff;
	--borderColor:#202E3F;

	--charterDakBlue:   rgb(65, 114, 156);   /*#41729c */
	--charterBlue:      rgb(75, 129, 177);   /*#4b81b1 */
	--charterMidBlue:	rgb(84, 148, 164);   /*#5494a4 */
	--charterLightBlue: rgb(211, 246, 255);  /*#d3f6ff */

	--zdrBorder:1px;
	--zdrPadding:4px;
	--zdrMarging:-4px;
	--fontSize:18pt;
	--fontFamily:Arial;
}

#ruler {
	position: absolute;
    visibility: hidden;
    white-space: nowrap;
}

.Calculator div:not(.Return_Display) {
    background-repeat: no-repeat;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

div.HudTable {
	background-repeat: no-repeat;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
}

.progress {
	background-color: black; /* bgColor */
	color: #b1d4dd; /* fgColor */
	text-decoration-color: #5494a4; /* fillColor */

	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	float: none;
	cursor: default;
}

.ToolbarLegacy {
	left: 0px;
	top: 0px;
	width: 100%;
	height: 60px;
	background-color: lightslategrey;
	float: none;
	cursor: default;
}

.toolicon {
	width: 1cm;
	height: 1cm;
	background-size: contain;
	margin: 0.25cm;
	float: left;
	position: relative;
	cursor: default;
}

#page-div {
	clip-path: inset(0% 0% 0% 0%);  /* or "auto" */
	-webkit-clip-path: inset(0% 0% 0% 0%);  /* or "auto" */
	z-index: 5;
	/*background-color: rgba(2,120,255, 0.3);*/
	position: absolute;
	pointer-events: none;
	touch-action: none;
}

#maindiv1 {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	bottom: 0px;
	background-color: white;
	cursor: default;
}
#mainpage{
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
}
#maindiv2 {
	position: absolute;
	left: 50%;
	top:  0px;
	width: 50%;
	bottom: 0px;
	cursor: default;
}

#renderCanvas1 {
  width:  200px;     /* 100%; */
  height: 100px;	   /* 100% */
  /* added to allow left property to work */
  /* position: relative;		 */
  /* absolute to fix restoreDOM bug: */
  position: absolute;
  /*background-color: Lime;*/
	background-color: rgb(250,250,250);
  /*display: none;*/
  touch-action: none;
  /* remove the border when focused */
  outline: none !important;
}

#textrenderCanvas {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	pointer-events: none;
}

#textentry {
	z-index: 10;
	display: none;
}

input {
	/*border: 1px solid #3d8b6f;
	border-style: none none solid none; */
	width: 100%;
	height: 30px;
	border-radius: 8px;
	margin-left: 0px;
	font-size: 10pt;
	color: #000000;

}
#canvasholder1 {
	position: absolute;
	width: 100%;
	top: 0px;
    bottom: 0px;
	/*background-color: var(--borderColor); */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.Calculator {
	box-shadow: 0px 0px 8px #888888;
	z-index: 190;
}

.calculator-animation {
	/*animation: anim 1s ease 0.5s;*/
	animation-fill-mode: both;
}

.calculator-animation-return {
	/*animation: anim2 1s ease 0.5s;*/
	animation-fill-mode: both;
}

@-webkit-keyframes anim_open {
	0% {
		transform: translateX(-105%);
	}
	100% {
		transform: translateX(0%);
	}
}

@keyframes anim_open {
    0% {
        transform: translateX(-105%);
    }
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes anim_close {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-105%);
	}
}

@keyframes anim_close {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-105%);
	}
}

.Calculator_NumberKeys {
	 background-color: #5494a4;

	/*background-position: center;*/

	 color: #e0ecef;
	/*fill: #ff0000;*/
	 border-color: #2c6c7d;
	 border-style: solid;
	 border-width: 1px;
	 vertical-align: middle;
	 text-align: center;
	 font-family: SourceSansPro-Regular;
	 position: absolute;
	 cursor: pointer;
 }

.Calculator_NumberKeys_Elementary {
	background-color: #0088ff;
	color: #e0ecef;
	/*fill: #ff0000;*/
	border-color: #2c6c7d;
	border-style: solid;
	border-width: 1px;
	vertical-align: middle;
	text-align: center;
	font-family: SourceSansPro-Regular;
	position: absolute;
	cursor: pointer;
}

.Calculator_Blank_Elementary {
	cursor: default;
}

.Calculator_Precision {
	background-color: unset; /*#9ec9d5;*/
	color: #5a98a8;
	border-style: none;
	vertical-align: middle;
	text-align: center;
	font-family: SourceSansPro-Regular;
	position: absolute;
	cursor: pointer;
}

.Calculator_NumberKeys_svg {
	background-color: #5494a4;
	color: #e0ecef;
	border-color: #2c6c7d;
	border-style: solid;
	border-width: 1px;
	/*vertical-align: middle;
	text-align: center;
	font-family: SourceSansPro-Regular;*/
	position: absolute;
	cursor: pointer;
}

.Calculator_ButtonDiv {
	border: solid 1px #2c6c7d;
}

.Calculator_OperatorKeys {
	background-color: #e0ecef;
	color: #2c6c7d;
	border-color: #2c6c7d;
	border-style: solid;
	border-width: 1px;
	vertical-align: middle;
	text-align: center;
	font-family: SourceSansPro-Regular;
	position: absolute;
	cursor: pointer;
}

.Calculator_Separator {
	background-color: #b1d4dd;
}

.Calculator_FunctionKeys {
	background-color: #4b81b1;
	/*background-position: center;*/
	color: #d3f6ff;
	border-color: #41729c;
	border-style: solid;
	border-width: 1px;
	vertical-align: middle;
	text-align: center;
	font-family: SourceSansPro-Regular;
	position: absolute;
	cursor: pointer;
}

.Calculator_FunctionKeys_svg {
	background-color: #4b81b1;
	color: #d3f6ff;
	border-color: #41729c;
	border-style: solid;
	border-width: 1px;
	position: absolute;
	cursor: pointer;
}

.Calculator_FunctionShift {
	background-color: #4b81b1;
	border-color: #41729c;
	border-style: solid;
	border-width: 1px;
	position: absolute;
	cursor: pointer;
}

.Calculator_FunctionDark {
	background-color: #41729c;
	color: #d3f6ff;
	vertical-align: middle;
	text-align: center;
	font-family: SourceSansPro-Regular;
	border-color: #386387;
	border-style: solid;
	border-width: 1px;
	position: absolute;
	cursor: pointer;
}

.Calculator_Base {
	background-color: #92bfc9;
	color: #d3f6ff; /* grid colour */
}

.Calculator_Base_Elementary {
	background-color: #57dbfb;
	color: #d3f6ff; /* grid colour */
}
.Calculator_Backspace_Elementary {
	background-color: #ff7700;
}

.Calculator_OperatorKeys_Elementary {
	background-color: #ccff00;
}

.Calculator_Display {
	background-color: #e0ecef;			/*  actual color  */
	border-color: #2c6c7d;
	border-style: solid;
	border-width: 1px;

	color: #2c6c7d;

	padding: 4px;

	box-shadow: inset 0px 0px 4px 1px #888888;		/* 1px 1px 3px 1px #bbbbbb; */
}

.Return_Display {
	background-color: unset;/* #e0ecef;*/
	color: #2c6c7d;
	position: relative;
	text-align: right;
	font-family: STIXGeneral-Regular;
	font-size: 20pt;

	padding: 4px;

	float: right;
}

.Calculator_TopBar {
	background-color: #9ec9d5;
	border-color: #e0ecef;
}

.Calculator_TopBarAlgSlider {
	background-color: #92bfc9;
	color: #5494a4;
}

.Calculator_TopBarText {
	background-color: #5494a4; /* faded text */
	color: #2c6c7d;
}

#controlsbar, #precminus, #precplus, #answitch, #calculatordisplay {
    position: absolute;
}

.HudTable { /* the table Div container */
	padding: 0;
	line-height: 1.1;
}
table {
	border-collapse: collapse;
}
th, td {
	border: 1px solid black;
	box-sizing: border-box;
}
th {
	background-color: #DDDDDD;
}

.nombreinclus {
	display: inline;
	position: relative;
}

#calcdisplaymenubg {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	display: none;
	z-index: 199;
}

#calcdisplaymenu {
	position: absolute;
	left: 0px;
	top: 0px;
	border: solid 1px black;
	border-radius: 8px;
	background-color: #F1F1F1;/*#E0ECEF;*/
	color: black;
	font-family: SourceSansPro-Regular, sans-serif;
	font-size: 18px;
	vertical-align: middle;
	padding: 4px 8px;
	z-index: 200;

	cursor: pointer;
	transition: transform .05s;
}

#calcdisplaymenu:hover {
	transform: scale(1.2);
}

.PileContainer {
	position: absolute;
	background: none;
	left: 0px;
	top: 0px;
	width: 100px;
	height: 800px;
	z-index: 180;
}

.PileClass {
	position: absolute;
	background: none;
	z-index: 180;
}

.PileItemClass {
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	border: 1px solid #55717d;
	background-color: #9ec9d5;
	background-size: contain;
	cursor: pointer;
	z-index: 180;
}

.PileItemClassTeacher2 {
	background-color: #b7e3ef;
	width: 30px;
	height: 30px;
	margin-left: 9px;
	margin-top: 3px;
	transition: width 0.2s linear, height 0.2s linear, margin-left 0.2s linear, margin-top 0.2s linear;
}

.PileItemClassTeacher--out {
	width: 40px;
	height: 40px;
	margin-left: 0px;
	margin-top: 0px;
}

.PileItemClassTeacher {
	background-color: #b7e3ef;
	border: 1px solid #55717d;
	margin-left: 9px;
	margin-top: 3px;
	width: 30px;
	height: 30px;
}

.FileItemClassSelected {
	background-color: #ebf4f5;
}

.PileItemClassMultishot {
	background-color: #fedb80;
}

.PileItemLabel {
	position: absolute;
	background: none;
	left: 0px;
	top: 0px;
	width: 100px;
	height: auto;
	font-family: SourceSansPro-Regular;
	font-size: 16px;
	text-align: center;
	z-index: 180;
}

.PileClickZone {
	background-color: transparent;
	cursor: default;
	z-index: 182;
	transition: opacity 0.4s;
}

.PileClickZone::after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-left: 8px solid black;
	opacity: 0.3;
	position: absolute;
	top: 50%;
	left: 5px;
	transform: translateY(-50%);
}

.PileClickZone--arrow::after {
	border-left: 8px solid #9ec9d5;
	opacity: 0.6;
}

.PileUnderBar {
	background-color: rgba(217, 234, 238, 0.9);
	border-radius: 10px;
	/*box-shadow: 0px 0px 8px #888888;*/
	/*margin-left: -10px;*/
	pointer-events: none;
	z-index: 179;
}

.PileGreyed {
	background-color: rgba(158, 201, 213, 0.5);
}

.PileLabelPopup {
	position: absolute;
	background-color: white;
	color: black;
	z-index: 185;
	cursor: pointer;
	font-family: SourceSansPro-Regular;
	font-size: 32pt;
	text-align: center;
	padding: 5px;
	box-shadow: 0px 0px 5px #888888;

}

.toolbar {
	z-index: 300;
}

.ToolbarExpress {
	position: relative;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 60px;
	background-color: #41729c;
}

.ToolbarExpress-button {
	width: 40px;
	height: 40px;
	top: 10px;
	position: absolute;
	background-size: contain;
	cursor: pointer;
}
.ToolbarExpress-button--reduced {
	height: 30px; /* using "transform: scale(0.7);" does not work with tooltip */
	top: 15px;
}
.ToolbarExpress-button--disabled {
	opacity: 0.3;
}

.pilebg {
	cursor: default;
}

.pulsate {
	animation: pulsate 0.4s ease-in 0s infinite alternate;
	opacity: 1.0;
}
@keyframes pulsate {
	0%   { opacity: 0.7 }
	100% { opacity: 0.1 }
}

/*.tooltip::before {*/
	/*content: attr(data-tooltip) ;*/

	/*font-size: 1em;*/
	/*position:absolute;*/
	/*z-index: 999;*/
	/*white-space:nowrap;*/
	/*bottom:9999px;*/
	/*left: 50%;*/
	/*background:#9cc9d5;*/
	/*color:black;*/
	/*padding:0px 7px;*/
	/*line-height: 24px;*/
	/*height: 24px;*/
	/*!*max-width:100%;*!*/
	/*border-radius: 10px;*/

	/*opacity: 0;*/
	/*transition:opacity 0.4s ease-out;*/
	/*transition-delay: 1s;*/

	/*transform: translateX(-50%);*/
/*}*/

/*.tooltip:hover::before {*/
	/*opacity: 1;*/
	/*bottom: -35px;*/
/*}*/

.selected {
	/* note: border is not adequate because it moves the div */
	/* todo: adapt the widths according to the currentzoom */
	box-shadow: 0 0 0.1cm 0.1cm var(--selectionColor) !important; /* h v blur spread color inset */
}

.edited {
	box-shadow: 0 0 0.1cm 0.1cm #4D90FE; /* h v blur spread color inset */
}

/* note: cursors are managed by state machine (e.g. text cursor must be set only if text is editable) */
.hilite, .clickable:hover, .choosable:hover {
	box-shadow: 0 0 0.1cm 0.05cm var(--hiliteColor); /* old value was #74B5EB */
}
.selected.choosable:hover {
	box-shadow: 0 0 0.1cm 0.1cm var(--hiliteColor);
}

.faded {
	opacity: 0.3;
}

.MCQ--student:hover {
	box-shadow: unset;
}

.SimpleTextEdit {
	position: absolute;
	margin-left: 4px;
	margin-top: 4px;
	user-select: auto;
	border: solid black 1px;
	min-width: 20px;
	/*div.style.minHeight = fs + 'pt'*/;
	cursor: text;
}

.SimpleTextEdit, .SimpleTextEdit > div {
	font-family: var(--fontFamily);
	font-size: var(--fontSize);
	color: var(--textColor)
}

.pulsBlockly > * {
	animation: pulsate 0.5s cubic-bezier(0.6, 0, 1.0, 1.0) 0s infinite alternate;
	opacity: 1.0;
}
@keyframes pulsBlockly {
	0%   { opacity: 0.4; }
	100% { opacity: 0.1 }
}
.nopulsBlockly {
	animation: none !important;
	-webkit-animation: none !important;
	-moz-animation: none !important;
	-o-animation: none !important;
	-ms-animation: none !important;
}


sup, sub {
	vertical-align: baseline;
	position: relative;
	top: -0.4em;
}
sub {
	top: 0.4em;
}

/*Remove default button style*/
.btn-rm-default {
	background-color: transparent;
	box-shadow: 0px 0px 0px transparent;
	border: 0px solid transparent;
	text-shadow: 0px 0px 0px transparent;
}

/*Remove default button style*/
.btn-rm-default:hover {
	background-color: transparent;
	box-shadow: 0px 0px 0px transparent;
	border: 0px solid transparent;
	text-shadow: 0px 0px 0px transparent;
}

/*Remove default button style*/
.btn-rm-default:focus {
	outline: none;
	background-color: transparent;
	box-shadow: 0px 0px 0px transparent;
	border: 0px solid transparent;
	text-shadow: 0px 0px 0px transparent;
}

/*New Button Style*/
.btn-rm-default {
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
	min-width: 20px;
	/*flex-grow: 1;*/
}


.blinking-cursor {
	transform: scale(1.5, 1.5);
	margin-left: -0.4vmin;
	z-index: 999;
	position: absolute;
	font-family: "Times New Roman";
	/*font-weight: 100;*/
	/*font-size: 3vmin;*/
	/*color: #2E3D48;*/
    color: red;
	-webkit-animation: 0.8s blink step-end infinite;
	-moz-animation: 0.8s blink step-end infinite;
	-ms-animation: 0.8s blink step-end infinite;
	-o-animation: 0.8s blink step-end infinite;
	animation: 0.8s blink step-end infinite;
}

@keyframes blink {
	from, to {
		color: transparent;
	}
	50% {
		color: orangered;
	}
}

@-moz-keyframes blink {
	from, to {
		color: transparent;
	}
	50% {
		color: orangered;
	}
}

@-webkit-keyframes blink {
	from, to {
		color: transparent;
	}
	50% {
		color: orangered;
	}
}

@-ms-keyframes blink {
	from, to {
		color: transparent;
	}
	50% {
		color: orangered;
	}
}

@-o-keyframes blink {
	from, to {
		color: transparent;
	}
	50% {
		color: orangered;
	}
}


.pointer-events-none {
	pointer-events: none;
}

/*Remove default button style*/
.remove-button-default {
	background-color: transparent;
	box-shadow: 0 0 0 transparent;
	border: 0 solid transparent;
	text-shadow: 0 0 0 transparent;
}

/*Remove default button style*/
.remove-button-default:hover {
	background-color: transparent;
	box-shadow: 0 0 0 transparent;
	border: 0 solid transparent;
	text-shadow: 0 0 0 transparent;
}

/*Remove default button style*/
.remove-button-default:focus {
	outline: none;
	background-color: transparent;
	box-shadow: 0 0 0 transparent;
	border: 0 solid transparent;
	text-shadow: 0 0 0 transparent;
}

#page-spinner-container {
	z-index: 999;
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: none;
	align-items: center;
	justify-content: center;
	background-color: white;
}

#page-spinner {
	width: 80;
	height: 80;
}
