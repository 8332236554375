html {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
  }
  
  /* mathia ***/
  .backgroundStarsIon {
    --background: url(/assets/gabarits/fond-etoile.jpg) no-repeat center center / cover;
  }
  .low-perf-mode .backgroundStarsIon,
  .low-perf-mode :host .backgroundStarsIon,
  .low-perf-mode.backgroundStarsIon,
  .wait-plt-ready.backgroundStarsIon,
  .wait-plt-ready .backgroundStarsIon,
  .wait-plt-ready :host .backgroundStarsIon {
    --background: url(/assets/gabarits/fond-etoile_ios.jpg) no-repeat center center / cover;
  }

  .backgroundStars {
  background: url(/assets/gabarits/fond-etoile_ios.jpg) no-repeat center center / cover;
  }
  .plt-is-ready:not(.low-perf-mode) .backgroundStars,
  .plt-is-ready:not(.low-perf-mode) :host .backgroundStars,
  .plt-is-ready:not(.low-perf-mode) :host ::ng-deep .backgroundStars,
  .plt-is-ready.backgroundStars:not(.low-perf-mode){
    background: url(/assets/gabarits/fond-etoile.jpg) no-repeat center center / cover;
  }
    /*** mathia */

  .waitUserActionAlertHolo {
    --backdrop-opacity: 0;
    background: url(/assets/gabarits/fond-etoile.jpg) no-repeat center center / cover;
  }
  .low-perf-mode .waitUserActionAlertHolo{
    background: url(/assets/gabarits/fond-etoile_ios.jpg) no-repeat center center / cover !important;
  }

  .fondHud {
    background-image: url('/assets/img/hud/fond.jpg');
  }
  .low-perf-mode .fondHud,
  .low-perf-mode :host .fondHud {
    background-image: url('/assets/img/hud/fond_ios.jpg');
  }

  .activityParticipantsBg {
    background-image: url('/assets/img/activityparticipant/background.jpg');
  }
  .low-perf-mode .activityParticipantsBg,
  .low-perf-mode :host .activityParticipantsBg {
    background-image: url('/assets/img/activityparticipant/background_ios.jpg');
  }

  .mathiaMascotteHome {
    background-image: url(../assets/mathia/mascotte3d/mascotte3d.png);
    background-size: 100% auto;
    background-position: center;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    align-self: flex-end;
    height: 80%;
    width: 23%;
    /* width: 12vh; */
    /* margin-left: 8vw;
    margin-right: 2vh;
    transform: translateY(15%); */
    /* border: 1px solid red */
  }
  .low-perf-mode .mathiaMascotteHome {
    background-image: url(../assets/mathia/mascotte3d/mascotte3d_ios.png);
  }

  @keyframes floatingHome {
    0% { transform: translate(0, -15%);}
    50%  { transform: translate(0, 0%);}
    100%  { transform: translate(0, -15%);}  
  }
  .mathiaMascotteHomeingAnimation {
    animation-name: floatingHome;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  .mathiaMascotteStarting {
      background-image: url(../assets/mathia/mascotte3d/mascotte3d.png);
      background-size: 100% auto;
      background-position: center;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      align-self: flex-end;
      height: 80%;
      width: 12vh;
      margin-left: 8vw;
      margin-right: 2vh;
      transform: translateY(15%);
      /* border: 1px solid red */
    }
  
    @keyframes floatingCustom {
      0% { transform: translate(0,  15%);}
      50%  { transform: translate(0, 0%);}
      100%  { transform: translate(0, 15%);}  
    }
    .mathiaMascotteStartingAnimation {
      animation-name: floatingCustom;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  
  #image-preloader{
    opacity:0; 
    pointer-events: none;
  }

  .leftMenuBackgrdMathia {
    background-image: url(/assets/toolbar/leftMenu/mathia-opak.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  