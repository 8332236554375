// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// html {
//   background: url(/assets/kidaia/background/fond-final-1080.jpg) no-repeat center center / cover !important;
//   top: 0;
//   position: fixed;
//   width: 100%;
//   height: 100%;
// }


//Scroll bar

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(180deg, #FFDC98 0%, #FCB731 100%)!important;
  background: -moz-linear-gradient(180deg, #FFDC98 0%, #FCB731 100%)!important;
  background: -webkit-linear-gradient(180deg, #FFDC98 0%, #FCB731 100%)!important;
  background: -webkit-linear-gradient(180deg, #FFDC98 0%, #FCB731 100%)!important;
  background: -o-linear-gradient(180deg, #FFDC98 0%, #FCB731 100%)!important;
  background: -ms-linear-gradient(180deg, #FFDC98 0%, #FCB731 100%)!important;
}

//**************  Pics  **************//

.logoKidaiaWhiteText {
  background: url(/assets/kidaia/logo/logoKidaiaWhiteV2.png) no-repeat bottom center/contain;
}

.backgroundStars {
  background: url(/assets/kidaia/background/fond-final-1080.png) no-repeat center center / cover !important;
}


.low-perf-mode .backgroundStars,
.low-perf-mode :host .backgroundStars,
.low-perf-mode.backgroundStars,
.wait-plt-ready.backgroundStars,
.wait-plt-ready :host .backgroundStars{
  background: url(/assets/kidaia/background/fond-final-1080_ios.png) no-repeat center center / cover !important;
}

.backgroundStarsIon {
  --background: url(/assets/kidaia/background/fond-final-1080.png) no-repeat center center / cover !important;
}
.low-perf-mode .backgroundStarsIon,
.low-perf-mode :host .backgroundStarsIon {
  --background: url(/assets/kidaia/background/fond-final-1080_ios.png) no-repeat center center / cover !important;
}

.waitLandscapeModeAlert {
  background: url(/assets/kidaia/background/fond-final-1080.png) no-repeat center center / cover !important;
}
.low-perf-mode .waitLandscapeModeAlert,
.low-perf-mode :host .waitLandscapeModeAlert,
.low-perf-mode.waitLandscapeModeAlert {
  background: url(/assets/kidaia/background/fond-final-1080_ios.png) no-repeat center center / cover !important;
}

.wUABackground {
  background: url(/assets/kidaia/background/fond-final-1080.png) no-repeat center center / cover;
  ion-backdrop {
    background: transparent;
  }
}
.low-perf-mode .wUABackground,
.low-perf-mode :host .wUABackground,
.low-perf-mode.wUABackground {
  background: url(/assets/kidaia/background/fond-final-1080_ios.png) no-repeat center center / cover !important;
}

.waitUserActionAlertHolo {
    --backdrop-opacity: 0;
    background: url(/assets/kidaia/background/fond-final-1080.png) no-repeat center center / cover;
}
.low-perf-mode .waitUserActionAlertHolo{
  background: url(/assets/kidaia/background/fond-final-1080_ios.png) no-repeat center center / cover !important;
}



.leftMenuBackgrd {
  background-image: url(/assets/kidaia/toolbar/leftMenu/kidaia-opak.png);
  // background-image: url(/assets/toolbar/leftMenu/kidaia-transparent.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.student-list-modal{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    --background: transparent;
    --backdrop-opacity: 0.5;
  }

  .student-list-modal .modal-wrapper{
    width: 98.5%;
    height: 97%;
    border-radius: 20px;
    border: double 4px transparent;
    border-radius: 30px 30px 30px 30px;
    background-image: linear-gradient(white, white), linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: flex-start;
  }

  // remove student alert:
  .studentRemovalAlert .alert-wrapper {      
    .alert-button-group {
      justify-content: space-evenly;
      flex-wrap:nowrap!important;
      align-items: center;
    }
  }

  .creationEditionStudentAlert .alert-message{
    font-size: 1.1em;
  }

  .creationEditionStudentAlert .alert-head{
    text-align: center;
  }

  .creationEditionStudentAlert{
    color: #721c24;
    font-family: var(--appFontFamily);
  }

  .creationEditionStudentAlert .alert-message p{
    color: #721c24;
    font-family: var(--appFontFamily);
    font-size: 1.1em;
    margin: 0.5em 0em;
  }

  .studentRemovalAlert .alert-message {
    font-size: 20px;
    color: #00AFEC;
    font-family: var(--appFontFamily);
  }

  .studentRemovalAlert {
    // ?
    ion-modal::part(backdrop) {
      --backdrop-opacity: 0;
      background: #cbebf7;
    }

    .alert-wrapper {
      max-width: 80vw;
      min-width: 60vw;
    }

    .alert-message {
      font-size: 20px;
      color: #2111D9;
      font-family: var(--appFontFamily);
    }
  
    .kidaiaCancelButton {
      text-transform: none!important;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-family: var(--appFontFamily);
      font-size: 20px!important;
      // font-size: calc(0.5vh + 0.5vw)!important;
      color: white!important;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      background: #F4539E;
      width: 100px;
    }

    .kidaiaConfirmButton {
      text-transform: none!important;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-family: var(--appFontFamily);
      font-size: 20px!important;
      // font-size: calc(0.5vh + 0.5vw)!important;
      color: white!important;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      background: #54EFCA;
      width: 100px;
    }

  }

ion-alert.select-alert {
  --background: white!important;

  button {
      color: white !important;
      // font-size: 20px !important;
  }

  .sc-ion-alert-md {
      color: #2111D9;
  }
}

ion-alert.kidaia-popover .alert-button-group button{
  color:#2111D9!important;
}

.kidaia-popover {
  ion-radio {
      --color-checked: #2111D9;
  }
}

ion-alert.kidaia-popover .alert-button-group button{
  color:#2111D9!important;
}

.popover-content{
  overflow: hidden!important;
}

.fontFam {
  font-family: var(--appFontFamily)!important;
  color: #0E0D3E !important;
}

:root {
  --ion-color-secondary: #1FCDB9;
  --ion-color-secondary-rgb: 0,102,0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #005a00;
  --ion-color-secondary-tint: #1a751a;

  // menu settings background color:
  // --settingsMenuColor: rgba(255, 255, 255, 0.85);
}

.opacityFull {
  opacity: 1;
}

.opacityNone {
  opacity: 0;
}

#renderCanvas1:not(.renderCanvas1HoloMode){
  background-color: #1f1c55!important;
}

.renderCanvas1HoloMode {
  background-color: #000000!important;
}

app-video .descriptionFullText {
  background: rgba(41, 167, 221, 0.45) !important;
}

#videosPage  .filterVideo {
  background-color: rgba(0, 88, 138, 1) !important;
}


app-video .buttonSelect {
  background-color: rgba(0, 88, 138, 1) !important;
}

